// Import css in order to create a chunk
import '../css/application.scss';

// Import all of the images for the webpacker to grab them and compile
require.context('../images', true);

function addClass(element, className) {
  var arr = element.className.split(" ");
  if (arr.indexOf(className) == -1) {
    element.className += " " + className;
  }
}

var hideButton = document.getElementById("hide-pwd")
if(hideButton) {
  hideButton.onclick = function(e) {
    e.preventDefault();
    var passwordField = document.getElementById("customer_password");
    passwordField.setAttribute("type", "password");
    addClass(hideButton, "hidden");
  };
}